// React components
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Responsive from '../clases/responsive'

// Detect device
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

// Local components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Animation from "../components/animation"

// Styles
import styles from "../sass/pages/home.module.scss"

// Images
import phone_3 from "../../static/img/phone-1.png"
import phone_2 from "../../static/img/phone-2.png"
import phone_1 from "../../static/img/phone-3.png"
import icon1 from "../assets/img/type-of-project-icons/icon-1.svg"
import icon2 from "../assets/img/type-of-project-icons/icon-2.svg"
import icon3 from "../assets/img/type-of-project-icons/icon-3.svg"
import icon4 from "../assets/img/type-of-project-icons/icon-4.svg"
import servicesIcon from "../assets/img/services-icons/request.svg"
import servicesIcon2 from "../assets/img/services-icons/route.svg"
import servicesIcon3 from "../assets/img/services-icons/trade.svg"

let lang = ""

const Home = () => {
  lang = useIntl().locale
  let deviceType = "mobile"

  const title = useIntl().formatMessage({ id: "home_header" })

  // References
  const animation = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const how = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const services = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const projects = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const types = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const landscape = React.useRef() as React.MutableRefObject<HTMLDivElement>

  const elements: any = []

  // Hooks
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scroll] = useState(0)
  const [load] = useState(0)
  const [device, setDevice] = useState(String);

  const margin: number = 400

  useEffect(() => {
    elements.push([animation, animation.current.offsetTop])
    elements.push([how, how.current.offsetTop])
    elements.push([projects, projects.current.offsetTop])
    elements.push([services, services.current.offsetTop])
    elements.push([types, types.current.offsetTop])
    elements.push([landscape, landscape.current.offsetTop])
    setDevice(Responsive.getDevice())
  }, [load])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scroll])

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goToURL = (e: any, url: string) => {
    window.location.assign(url)
    e.preventDefault()
  }

  const handleResize = () => {
    setDevice(Responsive.getDevice());
  }

  const _setScrollPosition = (value: number) => {
    setScrollPosition(value)
  }

  const onScroll = (e: any) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    // _setScrollPosition(winScroll);

    for (let i in elements) {
      if (winScroll > elements[i][1] - margin) {
        if (elements[i][0] === how) {
          elements[i][0].current.className =
            "container-small " + styles.headband + " " + styles.isActive
        } else if (elements[i][0] === landscape) {
          elements[i][0].current.className =
            "container-large " + styles.toronto + " " + styles.isActive
        }
      } else {
        if (elements[i][0] === how) {
          elements[i][0].current.className =
            "container-small " + styles.headband
        } else if (elements[i][0] === landscape) {
          elements[i][0].current.className = "container-large " + styles.toronto
        }
      }
    }
  }

  return (
    <Layout color="">
      <SEO lang={lang} title={title} />
      <article
        ref={animation}
        className={"container-small " + styles.animation}
      >
        <div>
          <h1>
            <FormattedMessage id="home_header" />
          </h1>
          <p>
            <FormattedMessage id="home_header_paragraph" />
          </p>
        </div>
        <figure>
          <Animation />
        </figure>
      </article>

      <article ref={how} className={"container-small " + styles.headband}>
        <div className={styles.container}>
          <div>
            <h2>
              <FormattedMessage id="home_first_title" />
            </h2>
            <p>
              <FormattedMessage id="home_first_paragraph" />
            </p>
            <button
              onClick={e => {
                goToURL(e, "/portfolio")
              }}
              className="btn btn-wire"
            >
              <FormattedMessage id="home_check_portfolio_button" />
            </button>
          </div>
          {
            device != "mobile" ?
            <div className={styles.phones}>
              <img src={phone_1} alt="Phone" />
              <img src={phone_2} alt="Phone" />
              <img src={phone_3} alt="Phone" />
            </div> :
            null
          }
        </div>
      </article>

      <article
        id="portfolio"
        ref={projects}
        className="container-small container-wide-mobile"
      >
        <h3 className={styles.space + " " + styles.spaceMobile}>
          <FormattedMessage id="home_projects_title" />
        </h3>
        <Link to={`/${lang}` + "/voy-de-viaje"}>
          <div className={"cardWide " + styles.voyDeViaje}>
            <div className="cardContentLarge">
              <p className="cardHashtags"></p>
              <p className="cardTitleLarge">
                <FormattedMessage id="first_project_title" />
              </p>
            </div>
          </div>
        </Link>
        <div className="grid-cards-2-1">
          <Link to={`/${lang}` + "/movie"}>
            <div className={"portfolioCard " + styles.movie}>
              <div className="cardContent">
                <p className="cardHashtags"></p>
                <p className="cardTitle">
                  <FormattedMessage id="second_project_title" />
                </p>
              </div>
            </div>
          </Link>
          <Link to={`/${lang}` + "/oca"}>
            <div className={"portfolioCard " + styles.oca}>
              <div className="cardContent">
                <p className="cardHashtags"></p>
                <p className="cardTitle">
                  <FormattedMessage id="third_project_title" />
                </p>
              </div>
            </div>
          </Link>
        </div>
      </article>

      <article ref={services} className="container-small container-wide-mobile">
        <h3 className={styles.space + " " + styles.spaceMobile}>
          <FormattedMessage id="home_services_title" />
        </h3>
        <div className={styles.container}>
          <div className={"grid-cards-3 " + styles.gridCardsServices}>
            <div className="whiteCard">
              <div className="whiteCardIcon">
                <img src={servicesIcon} alt="" />
              </div>
              <div className="whiteCardTitle">
                <p>
                  <FormattedMessage id="home_services_first_card_title" />
                </p>
              </div>
              <div className="whiteCardText">
                <p>
                  <FormattedMessage id="home_services_first_card_text" />
                </p>
              </div>
              {/* <div className="whiteCardLink"><a href="#"><FormattedMessage id="home_services_first_card_link"/></a></div> */}
            </div>

            <div className="whiteCard">
              <div className="whiteCardIcon">
                <img src={servicesIcon2} alt="" />
              </div>
              <div className="whiteCardTitle">
                <p>
                  <FormattedMessage id="home_services_second_card_title" />
                </p>
              </div>
              <div className="whiteCardText">
                <p>
                  <FormattedMessage id="home_services_second_card_text" />
                </p>
              </div>
              {/* <div className="whiteCardLink"><a href="#"><FormattedMessage id="home_services_second_card_link"/></a></div> */}
            </div>

            <div className="whiteCard">
              <div className="whiteCardIcon">
                <img src={servicesIcon3} alt="" />
              </div>
              <div className="whiteCardTitle">
                <p>
                  <FormattedMessage id="home_services_third_card_title" />
                </p>
              </div>
              <div className="whiteCardText">
                <p>
                  <FormattedMessage id="home_services_third_card_text" />
                </p>
              </div>
              {/* <div className="whiteCardLink"><a href="#"><FormattedMessage id="home_services_third_card_link"/></a></div> */}
            </div>
          </div>
        </div>
      </article>

      <div className={styles.spaceLarge}>
        <button className="centered-box btn btn-main " onClick={e => { goToURL(e, "mailto:hello@atticalabs.com")}}>
          <FormattedMessage id="get_in_touch" />
        </button>
      </div>

      <article ref={types}>
        <div className={styles.typeTopLine}></div>
        <div className={"container-large " + styles.blueBackground}>
          <div className={"grid-cards-2-1 " + styles.container}>
            <div>
              <h2>
                <FormattedMessage id="home_type_of_projects_title" />
              </h2>
              <p>
                <FormattedMessage id="home_type_of_projects_paragraph" />
              </p>
            </div>
          </div>
          <div className="grid-cards-4">
            <div className="blueCard">
              <div className="blueCardIcon">
                <div className={styles.icon}>
                  <img src={icon1} alt="" />
                </div>
              </div>
              <p>
                <FormattedMessage id="home_type_of_projects_card1" />
              </p>
            </div>
            <div className="blueCard">
              <div className="blueCardIcon">
                <div className={styles.icon}>
                  <img src={icon2} alt="" />
                </div>
              </div>
              <p>
                <FormattedMessage id="home_type_of_projects_card2" />
              </p>
            </div>
            <div className="blueCard">
              <div className="blueCardIcon">
                <div className={styles.icon}>
                  <img src={icon3} alt="" />
                </div>
              </div>
              <p>
                <FormattedMessage id="home_type_of_projects_card3" />
              </p>
            </div>
            <div className="blueCard">
              <div className="blueCardIcon">
                <div className={styles.icon}>
                  <img src={icon4} alt="" />
                </div>
              </div>
              <p>
                <FormattedMessage id="home_type_of_projects_card4" />
              </p>
            </div>
          </div>
        </div>
        <div className={styles.typeBottomLine}></div>
      </article>

      <article ref={landscape} className={"container-large " + styles.toronto}>
        <div className={"grid-cards-2 " + styles.container}>
          <h3>
            <FormattedMessage id="footer_title" />
          </h3>
        </div>
      </article>
    </Layout>
  )
}

export default Home
