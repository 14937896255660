import React from 'react';
import "../sass/modules/_animation.scss";

import personaje01 from "../../static/img/animation/personaje01.svg"
import bigote from "../../static/img/animation/bigote.svg"
import ojo01 from "../../static/img/animation/ojo01.svg"
import ojo02 from "../../static/img/animation/ojo02.svg"
import mano01 from "../../static/img/animation/mano01.svg"
import mano02 from "../../static/img/animation/mano02.svg"
import mano03 from "../../static/img/animation/mano03.svg"
import escritorioypc from "../../static/img/animation/escritorioypc.svg"
import luzmonitor from "../../static/img/animation/luzmonitor.svg"
import silla from "../../static/img/animation/silla.svg"
import maceta from "../../static/img/animation/maceta.svg"
import planta from "../../static/img/animation/planta.svg"
import cuadrito from "../../static/img/animation/cuadrito.svg"
import repisa from "../../static/img/animation/repisa.svg"
import piso from "../../static/img/animation/piso.svg"
import pizarra from "../../static/img/animation/pizarra.svg"
import globo from "../../static/img/animation/globo.svg"
import engranaje1 from "../../static/img/animation/engranaje1.svg"
import engranaje2 from "../../static/img/animation/engranaje2.svg"
import transicion1Frame01 from "../../static/img/animation/transicion1-frame01.svg"
import transicion1Frame02 from "../../static/img/animation/transicion1-frame02.svg"
import transicion1Frame03 from "../../static/img/animation/transicion1-frame04.svg"
import transicion1Frame04 from "../../static/img/animation/transicion1-frame04.svg"
import transicion1Frame05 from "../../static/img/animation/transicion1-frame05.svg"
import transicion1Frame06 from "../../static/img/animation/transicion1-frame06.svg"
import transicion1Frame07 from "../../static/img/animation/transicion1-frame07.svg"
import transicion1Frame08 from "../../static/img/animation/transicion1-frame08.svg"
import transicion1Frame09 from "../../static/img/animation/transicion1-frame09.svg"
import transicion1Frame10 from "../../static/img/animation/transicion1-frame10.svg"
import personaje02 from "../../static/img/animation/personaje02.svg"
import pie from "../../static/img/animation/pie.svg"
import transicion2Frame01 from "../../static/img/animation/transicion2-frame01.svg"
import transicion2Frame02 from "../../static/img/animation/transicion2-frame02.svg"
import transicion2Frame03 from "../../static/img/animation/transicion2-frame03.svg"
import transicion2Frame04 from "../../static/img/animation/transicion2-frame04.svg"
import transicion2Frame05 from "../../static/img/animation/transicion2-frame05.svg"
import transicion2Frame06 from "../../static/img/animation/transicion2-frame06.svg"
import transicion2Frame07 from "../../static/img/animation/transicion2-frame07.svg"
import personaje03 from "../../static/img/animation/personaje03.svg"
import piernas from "../../static/img/animation/piernas.svg"
import taza from "../../static/img/animation/taza.svg"
import tazacafe from "../../static/img/animation/tazacafe.svg"
import gotas0 from "../../static/img/animation/gotas0.svg"
import gotas1 from "../../static/img/animation/gotas1.svg"
import gotas2 from "../../static/img/animation/gotas2.svg"
import gotas3 from "../../static/img/animation/gotas3.svg"
import gotas4 from "../../static/img/animation/gotas4.svg"
import gotas5 from "../../static/img/animation/gotas5.svg"
import portatil from "../../static/img/animation/portatil.svg"
import cable from "../../static/img/animation/cable.svg"
import smartphone from "../../static/img/animation/smartphone.svg"
import brazo00 from "../../static/img/animation/brazo00.svg"
import brazo01 from "../../static/img/animation/brazo00.svg"
import brazo02 from "../../static/img/animation/brazo00.svg"
import brazo03 from "../../static/img/animation/brazo00.svg"
import brazo04 from "../../static/img/animation/brazo00.svg"
import brazo05 from "../../static/img/animation/brazo00.svg"
import brazo06 from "../../static/img/animation/brazo00.svg"
import brazo07 from "../../static/img/animation/brazo00.svg"
import graficas from "../../static/img/animation/graficas.svg"
import barra from "../../static/img/animation/barra.svg"
import columna from "../../static/img/animation/columna.svg"
import input from "../../static/img/animation/input.svg"
import input2 from "../../static/img/animation/input2.svg"
import input3 from "../../static/img/animation/input3.svg"
  
const Animation = () => {

    const path = "../../static/img/animation/";
  
    return (
      <div className="animacion-attica">
        <div className="animacion-attica-personaje01">
            {/* <img className="personaje" alt="personaje" src={ personaje01 } /> */}
            <img className="personaje" alt="personaje" src={ personaje01 } />
            <img className="bigote" alt="bigote" src={ bigote } />
            <img className="ojo01" alt="ojo01" src={ ojo01 } />
            <img className="ojo02" alt="ojo02" src={ ojo02 } />
            <div className="animacion-attica-mano">
                <img className="mano01" alt="mano01" src={ mano01 } />
                <img className="mano02" alt="mano02" src={ mano02 } />
                <img className="mano03" alt="mano03" src={ mano03 } />
            </div>       
        </div>

        <div className="animacion-attica-escritorio">
            <img className="escritorio" alt="escritorio" src={ escritorioypc } />
            <img className="luz" alt="luz" src={ luzmonitor } />
        </div>

        <img className="animacion-attica-silla" alt="animacion-attica-silla" src={ silla } />
        <img className="animacion-attica-maceta" alt="animacion-attica-maceta" src={ maceta } />
        <img className="animacion-attica-planta" alt="animacion-attica-planta" src={ planta } />
        <img className="animacion-attica-cuadrito" alt="animacion-attica-cuadrito" src={ cuadrito } />
        <img className="animacion-attica-repisa1" alt="animacion-attica-repisa1" src={ repisa } />
        <img className="animacion-attica-repisa2" alt="animacion-attica-repisa2" src={ repisa } />
        <img className="animacion-attica-piso"  alt="animacion-attica-piso" src={ piso } />

        <img className="animacion-attica-pizarra"  alt="animacion-attica-pizarra" src={ pizarra } />

        <div className="animacion-attica-globo">
            <img alt="animacion-attica-globo" className="globo" src={ globo } />
            <img alt="animacion-attica-globo" className="engranaje1" src={ engranaje1 } />
            <img alt="animacion-attica-globo" className="engranaje2" src={ engranaje2 } />
        </div>

        <div className="animacion-attica-transicion1">
            <img alt="animacion-attica-transicion1" src={ transicion1Frame01 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame02 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame03 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame04 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame05 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame06 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame07 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame08 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame09 } />
            <img alt="animacion-attica-transicion1" src={ transicion1Frame10 } />
        </div>

        <div className="animacion-attica-personaje02">
            <img className="personaje02" alt="personaje02" src={ personaje02 } />
            <img className="pie" alt="pie" src={ pie } />
            <img className="ojo01" alt="ojo01" src={ ojo01 } />
            <img className="ojo02" alt="ojo02" src={ ojo02 } />
        </div>

        <div className="animacion-attica-transicion2">
            <img alt="animacion-attica-transicion2" src={ transicion2Frame01 } />
            <img alt="animacion-attica-transicion2" src={ transicion2Frame02 } />
            <img alt="animacion-attica-transicion2" src={ transicion2Frame03 } />
            <img alt="animacion-attica-transicion2" src={ transicion2Frame04 } />
            <img alt="animacion-attica-transicion2" src={ transicion2Frame05 } />
            <img alt="animacion-attica-transicion2" src={ transicion2Frame06 } />
            <img alt="animacion-attica-transicion2" src={ transicion2Frame07 } />
        </div>

        <div className="animacion-attica-personaje03">
            <div className="flotar">
                <img className="personaje03" alt="personaje03" src={ personaje03 } />
                <img className="piernas" alt="piernas" src={ piernas } />
                
                <div className="taza">
                    <img alt="taza" src={ taza } />
                    <img className="tazacafe" alt="tazacafe" src={ tazacafe } />
                    <div className="gotas">
                        <img alt="gotas" src={ gotas0 } />
                        <img alt="gotas" src={ gotas1 } />
                        <img alt="gotas" src={ gotas2 } />
                        <img alt="gotas" src={ gotas3 } />
                        <img alt="gotas" src={ gotas4 } />
                        <img alt="gotas" src={ gotas5 } />
                    </div>
                </div>

                <div className="dispositivos">
                    <img className="portatil" alt="portatil" src={ portatil } />
                    <img className="cable" alt="cable" src={ cable } />
                    <img className="smartphone" alt="smartphone" src={ smartphone } />
                </div>

                <div className="brazos">
                    <img alt="brazos" src={ brazo00 } />
                    <img alt="brazos" src={ brazo01 } />
                    <img alt="brazos" src={ brazo02 } />
                    <img alt="brazos" src={ brazo03 } />
                    <img alt="brazos" src={ brazo04 } />
                    <img alt="brazos" src={ brazo05 } />
                    <img alt="brazos" src={ brazo06 } />
                    <img alt="brazos" src={ brazo07 } />
                </div>

                <div className="graficas">
                    <img alt="graficas" src={ graficas } />
                    <img alt="graficas" src={ barra } />
                    <img alt="graficas" src={ columna } />
                    <img alt="graficas" src={ columna } />
                    <img alt="graficas" src={ columna } />
                    <img alt="graficas" src={ input } />
                    <img alt="graficas" src={ input2 } />
                    <img alt="graficas" src={ input3 } />
                </div>
            </div>
        </div>

      </div>
    )
  }

export default Animation;