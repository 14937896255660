class Responsive {

    static getDevice() {
        const browserWidth = window.innerWidth || document.body.clientWidth;
        const breackpoints = { "mobile": 512, "tablet-p": 768, "tablet-l": 1024, 'desktop': 1440 };

        let value = "desktop";
        if(browserWidth <= breackpoints["mobile"]) {
            value = "mobile";
        } else if(browserWidth > breackpoints["mobile"] && browserWidth <= breackpoints["tablet-p"]) {
            value = "tablet-p";
        } else if(browserWidth > breackpoints["tablet-p"] && browserWidth <= breackpoints["tablet-l"]) {
            value = "tablet-l";
        } else if(browserWidth > breackpoints["tablet-l"] && browserWidth <= breackpoints["desktop"]) {
            value = "desktop";
        }
        return value;
    }
}

export default Responsive;